import React, { useRef, useState } from 'react'
import axios from 'axios'

import Container from './container'
import * as styles from './newsletter-banner.module.css'

function formHandler(email) {
  return axios({
    method: 'post',
    url: '/api/signup',
    data: {
      email: email
    },
  })
}

const NewsletterBanner = () => {
  let emailInput = useRef(null);

  const [state, setState] = useState({
    loading: false,
    success: false,
    error: false
  });

  const handleSubmit = (event, data) => {
    event.preventDefault()
    const email = emailInput.value

    if (!email) {
      alert('Please email your email')
    }

    setState({
      loading: true
    })

    formHandler(email).then(() => {
      setState({
        success: true,
        loading: false
      })
    }).catch((e) => {
      setState({
        error: true,
        loading: false
      })
    })
  }

  const { success, loading } = state
  const buttonText = (loading) ? 'Envoi en cours' : 'Envoyer'
  const handler = (loading) ? null : handleSubmit

  return (
    <div className={styles.newsletterBannerContainer}>
      <Container className={styles.newsletterBannerContent}>
        <h2>Inscrivez-vous à notre infolettre</h2>
        {success ?
          <p>Merci!</p>
          :
          <div><p>Pour recevoir des nouvelles, des recettes et bien plus!</p>
            <form
              onSubmit={handler}
            >
              <input
                placeholder="Inscrivez votre courriel"
                type="email"
                name="email"
                ref={el => (emailInput = el)}
                required
              />
              <button className='button'>{buttonText}</button>
            </form>
          </div>
        }
      </Container>
    </div>
  )
}

export default NewsletterBanner

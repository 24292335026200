import React from 'react'
import * as styles from './container.module.css'

const Container = ({ children, as = 'div', className = '' }) => {
  const Tag = as

  return (
    <Tag
      className={`${className} ${styles.container}`}
    >
      {children}
    </Tag>
  )
}

export default Container

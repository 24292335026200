import React from 'react'
import { Link } from 'gatsby'

import Container from './container'
import * as styles from './footer.module.css'

const Footer = () => (
  <div className={styles.footer}>
    <Container as="footer" className={styles.container}>
      <div>
        <svg className={styles.logo} xmlns="http://www.w3.org/2000/svg" width="323.046" height="186.633" viewBox="0 0 323.046 186.633">
          <g>
            <path d="M40.059,62.208q0-6.872-.117-13.746H12.683V12.956H72.13V48.462H45.1q0,6.876-.117,13.746,0,10.543.574,30.928H39.485Q40.059,74.581,40.059,62.208Z" transform="translate(-12.683 -11.582)" fill="#fffad5" />
            <path d="M49.009,85.31q-10.2-10.426-10.2-31.272t10.2-31.213Q59.2,12.461,79.707,12.461q20.615,0,30.811,10.364t10.195,31.213q0,20.848-10.195,31.272T79.707,95.732Q59.2,95.732,49.009,85.31Zm53.145-25.661q7.446-.566,9.967-1.89a4.074,4.074,0,0,0,2.52-3.721,3.957,3.957,0,0,0-2.52-3.663q-2.522-1.265-9.909-1.834t-22.506-.574q-15.009,0-22.4.574t-9.906,1.834a3.956,3.956,0,0,0-2.522,3.663,4.074,4.074,0,0,0,2.578,3.721q2.576,1.319,9.909,1.89t22.337.574Q94.711,60.223,102.155,59.649Z" transform="translate(37.471 -12.461)" fill="#fffad5" />
            <path d="M74,80.422q.229-17.41.23-27.146,0-10.2-.23-30.126-.112-5.157-.228-10.195h59.9V47.319H79.273v7.789q31.961,0,47.879-.347v5.616q-15.92-.345-47.879-.347,0,8.707.344,17.986.229,9.852.228,15.12H73.776Q73.888,86.718,74,80.422Z" transform="translate(103.809 -11.582)" fill="#fffad5" />
            <path d="M114.057,90.672a22.938,22.938,0,0,1-10.422-10.994,37.7,37.7,0,0,1-2.805-15.522q.112-4.811.114-15.12,0-22.1-.461-36.08h6.3q-.574,27.491-.572,31.5a14.345,14.345,0,0,0,2.175,8.308q2.173,3.147,8.247,4.867t17.525,1.718q11.683,0,17.753-1.718t8.249-4.925a14.634,14.634,0,0,0,2.175-8.363q0-13.057-.574-31.383h6.188q-.233,4.354-.344,16.035t-.117,23.025l.117,12.253A38.017,38.017,0,0,1,164.8,79.961a22.479,22.479,0,0,1-10.367,10.824q-7.446,4.066-20.159,4.068T114.057,90.672Z" transform="translate(155.094 -11.582)" fill="#fffad5" />
            <path d="M40.059,96.97q0-6.872-.117-13.746H12.683V47.718H72.13V83.224H45.1q0,6.876-.117,13.746,0,10.543.574,30.928H39.485Q40.059,109.343,40.059,96.97Z" transform="translate(-12.683 57.017)" fill="#fffad5" />
            <path d="M49.009,120.072q-10.2-10.426-10.2-31.272t10.2-31.213Q59.2,47.223,79.707,47.223q20.615,0,30.811,10.364T120.712,88.8q0,20.848-10.195,31.272T79.707,130.494Q59.2,130.494,49.009,120.072Zm53.145-25.661q7.446-.57,9.967-1.89a4.074,4.074,0,0,0,2.52-3.721,3.957,3.957,0,0,0-2.52-3.663q-2.522-1.265-9.909-1.834t-22.506-.574q-15.009,0-22.4.574t-9.906,1.834A3.956,3.956,0,0,0,44.883,88.8a4.074,4.074,0,0,0,2.578,3.721q2.576,1.319,9.909,1.89t22.337.574Q94.711,94.985,102.155,94.411Z" transform="translate(37.471 56.139)" fill="#fffad5" />
            <path d="M74,115.184q.229-17.41.23-27.146,0-10.2-.23-30.126-.112-5.157-.228-10.195h59.9V82.081H79.273V89.87q31.961,0,47.879-.347v5.613q-15.92-.341-47.879-.344,0,8.707.344,17.986.229,9.852.228,15.12H73.776Q73.888,121.48,74,115.184Z" transform="translate(103.809 57.017)" fill="#fffad5" />
            <path d="M114.057,125.434a22.931,22.931,0,0,1-10.422-11,37.68,37.68,0,0,1-2.805-15.519q.112-4.811.114-15.12,0-22.105-.461-36.08h6.3q-.574,27.491-.572,31.5a14.345,14.345,0,0,0,2.175,8.308q2.173,3.147,8.247,4.867t17.525,1.718q11.683,0,17.753-1.718t8.249-4.925a14.634,14.634,0,0,0,2.175-8.363q0-13.057-.574-31.383h6.188q-.233,4.354-.344,16.035t-.117,23.025l.117,12.253a38.017,38.017,0,0,1-2.808,15.691,22.479,22.479,0,0,1-10.367,10.824q-7.446,4.066-20.159,4.068T114.057,125.434Z" transform="translate(155.094 57.017)" fill="#fffad5" />
          </g>
        </svg>
        <hr className={styles.regularMargin} />
        <p>
          Tofutofu Inc.<br />
          871 Rue Mélanie,<br />
          Laval, Québec<br />
          H7X 3V3
        </p>
        <hr className={styles.regularMargin} />
      </div>
      <div>
        <ul className={styles.socialMedias}>
          <li className={styles.socialMediasItem}>
            <a href="https://www.facebook.com/AlloTofutofu" target="_blank" rel="noreferrer noopener" >
              <span className={styles.facebook} />
            </a>
          </li>
          <li className={styles.socialMediasItem}>
            <a href="https://www.linkedin.com/company/tofutofu/" target="_blank" rel="noreferrer noopener">
              <span className={styles.linkedin} />
            </a>
          </li>
          <li className={styles.socialMediasItem}>
            <a href="https://www.instagram.com/tofutofu.ca/" target="_blank" rel="noreferrer noopener" >
              <span className={styles.instagram} />
            </a>
          </li>
        </ul>
        <hr />
        <p className={styles.email}>Pour toute question, écrivez-nous à<br />
          <a href="mailto:allo@tofutofu.ca">allo@tofutofu.ca</a>
        </p>
        <hr />
        <p className={styles.phone}>
          <a href="tel:15146784075">+1 514 678-4075</a>
        </p>
        <hr />
      </div>
      <div>
        <ul className={styles.navigation}>
          <li className={styles.navigationItem}>
            <Link to="/nous-trouver" activeClassName="active">
              Nous trouver
            </Link>
          </li>
          <li className={styles.navigationItem}>
            <Link to="/produits" activeClassName="active">
              Produits
            </Link>
          </li>
          <li className={styles.navigationItem}>
            <Link to="/recettes" activeClassName="active">
              Recettes
            </Link>
          </li>
          <li className={styles.navigationItem}>
            <Link to="/foire-aux-questions" activeClassName="active">
              FAQ
            </Link>
          </li>
        </ul>
      </div>
    </Container>
  </div>
)

export default Footer

// extracted by mini-css-extract-plugin
export var container = "navigation-module--container--133f4";
export var facebook = "navigation-module--facebook--96eaf";
export var instagram = "navigation-module--instagram--81459";
export var linkedin = "navigation-module--linkedin--40736";
export var logo = "navigation-module--logo--be047";
export var logoLink = "navigation-module--logoLink--0ea2e";
export var navContainer = "navigation-module--navContainer--6c06f";
export var navContainerWithBg = "navigation-module--navContainerWithBg--abb66";
export var navigation = "navigation-module--navigation--92a8b";
export var navigationItem = "navigation-module--navigationItem--d3edd";
export var scroll = "navigation-module--scroll--d5456";
export var socialMedias = "navigation-module--socialMedias--e9e7b";
export var socialMediasItem = "navigation-module--socialMediasItem--98239";
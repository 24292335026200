import React from 'react'
import { Link } from 'gatsby'

import * as styles from './navigation.module.css'

const Navigation = ({location, closeMenu}) => {

  const navigationContainer = React.createRef()

  try {
    window.addEventListener('scroll', () => {
      let y = 1 + (window.scrollY || window.pageYOffset) / 150
      y = y < 1 ? 1 : y // ensure y is always >= 1 (due to Safari's elastic scroll)
      if(navigationContainer.current !== null && y > 1){
        navigationContainer.current.className = `${styles.navContainer} ${styles.scroll}`
      } else if (navigationContainer.current !== null && y === 1 && location.pathname === '/'){
        navigationContainer.current.className = styles.navContainer
      }
    });
  } catch(e) {

  }

  return <div className={location.pathname === '/' ? styles.navContainer : styles.navContainerWithBg} ref={navigationContainer}>
    <nav role="navigation" className={styles.container} aria-label="Main">
      <Link to="/" className={styles.logoLink}>
        <span className={styles.logo} />
      </Link>
      <ul className={styles.navigation}>
        <li className={styles.navigationItem}>
          <Link to="/nous-trouver" activeClassName="active" onClick={() => closeMenu()}>
            Nous trouver
          </Link>
        </li>
        <li className={styles.navigationItem}>
          <Link to="/produits/" activeClassName="active" onClick={() => closeMenu()}>
            Produits
          </Link>
        </li>
        <li className={styles.navigationItem}>
          <Link to="/recettes/" activeClassName="active" onClick={() => closeMenu()}>
            Recettes
          </Link>
        </li>
        <li className={styles.navigationItem}>
          <Link to="/foire-aux-questions" activeClassName="active" onClick={() => closeMenu()}>
            FAQ
          </Link>
        </li>
      </ul>
      <ul className={styles.socialMedias}>
        <li className={styles.socialMediasItem}>
          <a href="https://www.facebook.com/AlloTofutofu" target="_blank" rel="noreferrer noopener" >
            <span className={styles.facebook} />
          </a>
        </li>
        <li className={styles.socialMediasItem}>
          <a href="https://www.linkedin.com/company/tofutofu/" target="_blank" rel="noreferrer noopener">
            <span className={styles.linkedin} />
          </a>
        </li>
        <li className={styles.socialMediasItem}>
          <a href="https://www.instagram.com/tofutofu.ca/" target="_blank" rel="noreferrer noopener" >
            <span className={styles.instagram} />
          </a>
        </li>
      </ul>
    </nav>
  </div>
}

export default Navigation

import React, { useRef, useEffect, useState } from "react"
import mapboxgl from "!mapbox-gl"
import bbox from "@turf/bbox"
import { multiPoint } from "@turf/helpers"
import Markers from "./markers"
import "mapbox-gl/dist/mapbox-gl.css"

const mapContainerStyle = {
  width: "65vw",
  height: "75vh",
}

const mapContainerStyleMobile = {
  width: "100vw",
  height: "50vh",
}

const Map = props => {

  const mapContainerRef = useRef(null)

  const [map, setMap] = useState(null)

  useEffect(() => {

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: process.env.GATSBY_MAPBOX_TOKEN,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [-72.40, 45.45],
      zoom: 8,
    })

    map.addControl(new mapboxgl.NavigationControl(), "top-right")

    setMap(map)

    return () => map.remove()
  }, [])

  useEffect(() => {

    if (!map) return

    if (props.stores && props.stores.length !== 0) {
      const coords = []
      props.stores.forEach(store => {
        coords.push([store.longitudeLatitude.lon, store.longitudeLatitude.lat])
      })
      const feature = multiPoint(coords)
      const box = bbox(feature)

      map.fitBounds(
        [
          [box[0], box[1]],
          [box[2], box[3]],
        ],
        {
          center: props.selectedStoreCoord ? [props.selectedStoreCoord.lon, props.selectedStoreCoord.lat] : [-73.389368, 45.999135],
          zoom: props.zoom ? props.width > 767 ? Number(props.zoom) : Number(props.zoom) - 2 : 7,
          padding: 40,
          maxZoom: 17,
          duration: 4000,
        }
      )
    } else {
      map.easeTo({
        center: [-73.689368, 45.589135],
        zoom: 10,
        duration: 2000,
      })
    }
  }, [map, props.stores])

  return (
    <div ref={mapContainerRef} style={props.width > 767 ? mapContainerStyle : mapContainerStyleMobile}>
      {props.stores && map && <Markers map={map} stores={props.stores} />}
    </div>
  )
}

export default Map

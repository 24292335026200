import React, { useRef, useEffect } from "react"
import { navigate } from "gatsby"
import mapboxgl from "mapbox-gl"

const Marker = ({ map, store }) => {
  const markerRef = useRef()

  useEffect(() => {
    const el = document.createElement('div')
    el.className = 'marker'
    el.style.backgroundImage = `url('${window.location.origin}/images/marker.svg')`
    el.style.width = '35px'
    el.style.height = '35px'
    el.style.backgroundSize = '65%'
    el.style.backgroundRepeat = 'no-repeat'
    el.style.backgroundPosition = 'center center'

    el.addEventListener('click', () => {
      navigate('/nous-trouver/' + store.regionSlug + '/' + store.slug)
    })

    const marker = new mapboxgl.Marker(el)
      .setLngLat([store.longitudeLatitude.lon, store.longitudeLatitude.lat])
      .addTo(map)

    return () => marker.remove()
  })

  return <div ref={markerRef} />
}

const Markers = ({ map, stores }) => {
  return (
    <>
      {stores &&
        stores.map((store) => (
          <Marker key={store.slug} map={map} store={store} />
        ))}
    </>
  )
}

export default Markers

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-foire-aux-questions-js": () => import("./../../../src/pages/foire-aux-questions.js" /* webpackChunkName: "component---src-pages-foire-aux-questions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nous-trouver-js": () => import("./../../../src/pages/nous-trouver.js" /* webpackChunkName: "component---src-pages-nous-trouver-js" */),
  "component---src-pages-produits-js": () => import("./../../../src/pages/produits.js" /* webpackChunkName: "component---src-pages-produits-js" */),
  "component---src-pages-recettes-js": () => import("./../../../src/pages/recettes.js" /* webpackChunkName: "component---src-pages-recettes-js" */),
  "component---src-templates-product-post-js": () => import("./../../../src/templates/product-post.js" /* webpackChunkName: "component---src-templates-product-post-js" */),
  "component---src-templates-recipe-post-js": () => import("./../../../src/templates/recipe-post.js" /* webpackChunkName: "component---src-templates-recipe-post-js" */),
  "component---src-templates-region-post-js": () => import("./../../../src/templates/region-post.js" /* webpackChunkName: "component---src-templates-region-post-js" */),
  "component---src-templates-store-post-js": () => import("./../../../src/templates/store-post.js" /* webpackChunkName: "component---src-templates-store-post-js" */)
}


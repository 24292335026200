import React from 'react'

import Container from './container'
import * as styles from './online-stores-banner.module.css'

const OnlineStoreBanner = () => {

  return (
    <div className={styles.bannerContainer}>
      <Container className={styles.bannerContent}>
        <div>
          <h2>Trouvez-nous en ligne!</h2>
          <a href='https://montreal.lufa.com/fr/marche/recherche/tofutofu' className="button green" target="_blank" rel="noreferrer noopener">Lufa</a>
        </div>
      </Container>
    </div>
  )
}

export default OnlineStoreBanner

// extracted by mini-css-extract-plugin
export var container = "footer-module--container--f1eb9";
export var email = "footer-module--email--b4456";
export var facebook = "footer-module--facebook--3a644";
export var footer = "footer-module--footer--a2fe4";
export var instagram = "footer-module--instagram--e5be1";
export var linkedin = "footer-module--linkedin--6e82d";
export var logo = "footer-module--logo--9ab9c";
export var navigation = "footer-module--navigation--c1c68";
export var navigationItem = "footer-module--navigationItem--f8ae3";
export var phone = "footer-module--phone--f5285";
export var regularMargin = "footer-module--regularMargin--339d1";
export var socialMedias = "footer-module--socialMedias--fc211";
export var socialMediasItem = "footer-module--socialMediasItem--60fc9";
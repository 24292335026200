import React  from 'react'
import { Link } from 'gatsby'
import './variables.css'
import './global.css'
import Seo from './seo'
import Navigation from './navigation'
import Footer from './footer'
import Map from "./map"
import NewsletterBanner from '../components/newsletter-banner'
import OnlineStoreBanner from '../components/online-stores-banner'
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import ReactPixel from 'react-facebook-pixel';

import { reveal as Menu } from 'react-burger-menu'

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      scroll: false
    };
  }

  render() {

    const mobileNavigationContainer = React.createRef()

    let width = 0;
    try {
        width = window.innerWidth;
        window.addEventListener('scroll', () => {
          let y = 1 + (window.scrollY || window.pageYOffset) / 150
          y = y < 1 ? 1 : y // ensure y is always >= 1 (due to Safari's elastic scroll)
          if(mobileNavigationContainer.current !== null && y === 1) {
            this.setState({scroll: false})
          } else {
            this.setState({scroll: true})
          }
        });

        if (!(getCookieConsentValue("consentAccepted") === "true")) {
          ReactPixel.revokeConsent();
        } else {
          ReactPixel.init('1092784565107408');
          ReactPixel.pageView();
        }
    } catch(e) {}

    const { children } = this.props
    const regions = this.props.data?.allContentfulRegion?.nodes
    const stores = regions?.reduce((prev, curr) => {
      if (curr.stores !== null) {
        return[
          ...prev,
          ...curr.stores.map(store => Object.assign({}, {regionSlug: curr.slug}, store))
        ]
      } else {
        return prev
      }
    }, [])
    const selectedStoreCoord = this.props.pageContext?.longitudeLatitude
    const zoom = this.props.pageContext?.zoom || 7

    const handleStateChange = (state) => {
      this.setState({menuOpen: state.isOpen})
    }

    const closeMenu = () => {
      this.setState({menuOpen: false})
    }

    return (
      <>
        <CookieConsent
          style={{ background: "#008b6a", color: "#FFFAD5" }}
          buttonText="Accepter"
          enableDeclineButton
          declineButtonText="Refuser"
          onAccept={() => {
            ReactPixel.init('1092784565107408');
            ReactPixel.pageView();
          }}
          onDecline={() => {
            ReactPixel.revokeConsent();
          }}
          cookieName="consentAccepted"
        >Afin d’assurer le bon fonctionnement du site, de mesurer sa fréquentation, d'optimiser l’expérience utilisateur, de réaliser des études statistiques, de mesurer la performance des campagnes publicitaires, nous vous informons que nous et nos partenaires sommes susceptibles d’utiliser des cookies. Libre à vous d'accepter, de refuser et de changer d'avis à tout moment.</CookieConsent>
        <Seo />
        <div id="outer-container" className={`${this.props.location.pathname === '/' ? 'home' : this.props.location.pathname.split('/').slice(-1)} ${this.state.scroll ? 'scroll' : ''} ${this.state.menuOpen ? 'mobile-menu-opened' : 'mobile-menu-closed'}`}>
          {width < 769 &&
          <div ref={mobileNavigationContainer} className='mobile-menu-bar'>
            <Link to="/">
              <span className='mobile-logo' />
            </Link>
            <Menu
              pageWrapId={ "page-wrap" }
              outerContainerId={ "outer-container" }
              right
              width={ 280 }
              isOpen={this.state.menuOpen}
              onStateChange={(state) => handleStateChange(state)}
              customBurgerIcon={<svg width="75px" height="75px" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
                <line x1="16" x2="56" y1="26" y2="26" fill="none" stroke="#fff"/>
                <line x1="16" x2="56" y1="36" y2="36" fill="none" stroke="#fff"/>
                <line x1="16" x2="56" y1="46" y2="46" fill="none" stroke="#fff"/>
            </svg>}
              customCrossIcon={<svg width="75px" height="75px" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
                <line x1="17.5" x2="54.5" y1="17.5" y2="54.5" fill="none" stroke="#000000"/>
                <line x1="54.5" x2="17.5" y1="17.5" y2="54.5" fill="none" stroke="#000000"/>
            </svg>}
            >
              <Navigation location={this.props.location} closeMenu={closeMenu} />
            </Menu>
          </div>}
          <main id="page-wrap">
            {width > 768 && <Navigation location={this.props.location} closeMenu={closeMenu} />}
            <div className={this.props.location.pathname.includes('/nous-trouver') ? 'find-us-page' : '' }>
              {this.props.location.pathname.includes('/nous-trouver') && <Map stores={stores} selectedStoreCoord={selectedStoreCoord} zoom={zoom} width={width} />}
              <main>{children}</main>
            </div>
            {this.props.location.pathname.includes('/nous-trouver') && <OnlineStoreBanner />}
            <NewsletterBanner />
            <Footer />
          </main>
        </div>
      </>
    )
  }
}

export default Layout
